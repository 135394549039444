<!-- eslint-disable no-restricted-syntax -->
<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col xl="9" lg="8" md="7" class="d-none d-md-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- tree -->
            <v-img cover height="100%" src="@/assets/images/misc/hatko-banner.jpg"></v-img>
          </div>
        </v-col>

        <v-col
          xl="3"
          lg="4"
          md="5"
          class="d-flex align-center auth-bg"
          :style="!isDark ? 'background-color:#efeef0' : null"
        >
          <!--/ brand logo -->
          <v-card flat color="transparent">
            <img src="@/assets/images/logos/hatko-logo.png" width="100%" alt="logo" class="mb-0 mb-md-16" />

            <!-- login form -->
            <v-card-text class="px-0 mt-3">
              <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                <v-text-field
                  v-model="username"
                  :label="$t('kullaniciAdi')"
                  filled
                  :background-color="isDark ? '#373351' : 'white'"
                  class="mb-3 rounded-lg overflow-hidden"
                  hide-details="auto"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :background-color="isDark ? '#373351' : 'white'"
                  autocomplete="new-password"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  hide-details="auto"
                  filled
                  :label="$t('sifre')"
                  class="mb-6 rounded-lg overflow-hidden"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>

                <div class="d-flex align-center justify-space-between flex-wrap">
                  <!-- forget link -->
                  <router-link
                    :to="{ name: 'auth-forgot-password' }"
                    class="ms-3 font-weight-medium ls-n1"
                    :class="isDark ? 'white--text' : 'primary--text'"
                  >
                    {{ $t('parolaUnuttum') }}
                  </router-link>
                  <v-btn
                    rounded
                    color="tertiary"
                    type="submit"
                    class="text-capitalize text-body-2 white--text"
                    x-large
                    :loading="loading"
                  >
                    {{ $t('girisYap') }}
                  </v-btn>
                </div>
                <div class="container">
                  <v-btn
                    class="mt-6"
                    text
                    color="primary"
                    v-for="lang in languages"
                    :key="lang.info.code"
                    @click="changeLang(lang.info.code)"
                  >
                    {{ lang.info.name }}
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="dialogForm" :overlay-opacity="0.9" persistent max-width="800">
      <v-card color="nightDark">
        <v-card-title>
          <v-icon left large>
            {{ icons.mdiDraw }}
          </v-icon>
          {{ $t('sozlesmeler') }}
          <v-spacer />
          <v-btn icon @click="dialogClose()">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-stepper v-model="e6" vertical class="pb-0">
            <v-stepper-step :complete="e6 > 1" step="1">
              <div class="d-flex align-center">
                <v-icon v-if="e6 > 1" color="secondary" left>
                  {{ icons.mdiCheckBold }}
                </v-icon>
                <h3 class="font-weight-medium" :class="e6 === 1 ? 'text-h5' : 'text-body-2'">
                  {{ $t('kullaniciVeUyelikSozlesmesi') }}
                </h3>
              </div>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2">
                <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                  <div v-if="lang == 'tr'"><UyelikSozlesmesiTR /></div>
                  <div v-if="lang == 'en'"><UyelikSozlesmesiEN /></div>
                </perfect-scrollbar>
              </v-card>
              <v-card>
                <v-card-actions class="px-0 align-center">
                  <v-checkbox v-model="checkbox1" hide-details dense class="pa-0 ma-0">
                    <template v-slot:label>
                      <div class="text-caption">{{ $t('kabulEdiyorum') }}</div>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>

                  <v-btn color="secondary" :disabled="!checkbox1" depressed class="text-capitalize" @click="e6 = 2">
                    {{ $t('devam') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-divider></v-divider>

            <v-stepper-step :complete="e6 > 2" step="2">
              <div class="d-flex align-center">
                <v-icon v-if="e6 > 2" color="secondary" left>
                  {{ icons.mdiCheckBold }}
                </v-icon>
                <h3 class="font-weight-medium" :class="e6 === 2 ? 'text-h5' : 'text-body-2'">
                  {{ $t('kvkAcikRizaBeyan') }}
                </h3>
              </div>
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2">
                <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                  <div v-if="lang == 'tr'"><AcikRizaBeyanTR /></div>
                  <div v-if="lang == 'en'"><AcikRizaBeyanEN /></div>
                </perfect-scrollbar>
              </v-card>

              <v-card>
                <v-card-actions class="px-0 align-center">
                  <v-checkbox v-model="checkbox2" hide-details dense class="pa-0 ma-0">
                    <template v-slot:label>
                      <div class="text-caption">{{ $t('kabulEdiyorum') }}</div>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>

                  <v-btn color="secondary" :disabled="!checkbox2" depressed class="text-capitalize" @click="e6 = 3">
                    {{ $t('devam') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-divider></v-divider>
            <v-stepper-step :complete="e6 > 3" step="3">
              <div class="d-flex align-center">
                <v-icon v-if="e6 > 3" color="secondary" left>
                  {{ icons.mdiCheckBold }}
                </v-icon>
                <h3 class="font-weight-medium" :class="e6 === 3 ? 'text-h5' : 'text-body-2'">
                  {{ $t('kvkAydinlatmaMetni') }}
                </h3>
              </div>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2">
                <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                  <div v-if="lang == 'tr'"><KullaniciAydinlatmaTR /></div>
                  <div v-if="lang == 'en'"><KullaniciAydinlatmaEN /></div>
                </perfect-scrollbar>
              </v-card>

              <v-card>
                <v-card-actions class="px-0 align-center">
                  <v-checkbox v-model="checkbox3" hide-details dense class="pa-0 ma-0">
                    <template v-slot:label>
                      <div class="text-caption">{{ $t('kabulEdiyorum') }}</div>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>

                  <v-btn color="secondary" :disabled="!checkbox3" depressed class="text-capitalize" @click="e6 = 4">
                    {{ $t('devam') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="e6 !== 4" block color="secondary" @click="handleContractsSubmit">
            {{ $t('gonder') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import i18n from '@/plugins/i18n.js'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiCheckBold, mdiClose, mdiDraw, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { postData } from '@utils'
import { getCurrentInstance, ref, onMounted } from '@vue/composition-api'
import Vue from 'vue'
import UyelikSozlesmesiTR from './sozlesmeler/UyelikSozlesmesiTR.vue'
import UyelikSozlesmesiEN from './sozlesmeler/UyelikSozlesmesiEN.vue'
import AcikRizaBeyanTR from './sozlesmeler/acikRizaBeyan/AcikRizaBeyanTR.vue'
import AcikRizaBeyanEN from './sozlesmeler/acikRizaBeyan/AcikRizaBeyanEN.vue'
import KullaniciAydinlatmaTR from './sozlesmeler/kullaniciAydinlatma/KullaniciAydinlatmaTR.vue'
import KullaniciAydinlatmaEN from './sozlesmeler/kullaniciAydinlatma/KullaniciAydinlatmaEN.vue'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
    UyelikSozlesmesiTR,
    UyelikSozlesmesiEN,
    AcikRizaBeyanTR,
    AcikRizaBeyanEN,
    KullaniciAydinlatmaEN,
    KullaniciAydinlatmaTR,
  },
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const userData = ref(null)
    const loading = ref(false)
    const checkbox1 = ref(false)
    const checkbox2 = ref(false)
    const checkbox3 = ref(false)

    const dialogForm = ref(false)
    const { isDark } = useAppConfig()
    const e6 = ref(1)
    const vm = getCurrentInstance()?.proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const username = ref('')
    const password = ref('')
    const lang = ref('')

    // const username = ref('maverabilisim@gmail.com')
    // const password = ref('010203')

    // const username = ref('plasiyer')
    // const password = ref('010203')

    // const username = ref('MT0005471')
    // const password = ref('19821985')
    const perfectScrollbarOptions = {
      minScrollbarLength: 60,
      wheelPropagation: true,
    }
    const errorMessages = ref([])

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true

      postData({
        method: 'login',
        username: username.value,
        password: password.value,
        lang: lang.value,
      }).then(data => {
        if (data.error === 0) {
          if (
            data.detail.kullanici_uyelik_sozlesmesi === 0 &&
            data.detail.kullanici_uyelik_sozlesmesi === 0 &&
            data.detail.kvk_aydinlatma_metni === 0
          ) {
            const { accessToken } = data
            localStorage.setItem('accessToken', accessToken)
            userData.value = data
            handleContracts()
          } else {
            loading.value = false
            const { accessToken } = data
            localStorage.setItem('accessToken', accessToken)
            const { detail: user } = data
            const userAbility = data.ability

            vm.$ability.update(userAbility)
            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            delete user.ability
            localStorage.setItem('userData', JSON.stringify(user))
            localStorage.setItem('currency', data.detail.currency)
            localStorage.setItem('paymcondtxt', data.detail.paymcondtxt)

            router.push('/dashboard')

            setTimeout(() => {
              location.reload() // Reload the page after a brief delay
            }, 650)
          }
        } else {
          loading.value = false
          Vue.swal({
            title: i18n.t('hata'),
            text: data.msg,
            icon: 'warning',
            background: '#FF4C51',
            confirmButtonColor: '#000',
          })
        }
      })
    }
    const handleContracts = () => {
      dialogForm.value = true
    }
    const handleContractsSubmit = async () => {
      postData({
        method: 'approveContract',
        kullanici_uyelik_sozlesmesi: 1,
        kvk_acik_riza_beyani: 1,
        kvk_aydinlatma_metni: 1,
      })
        .then(data => {
          if (data.error === 0) {
            const { detail: user } = userData.value
            const userAbility = userData.value.ability

            vm.$ability.update(userAbility)
            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            delete user.ability
            localStorage.setItem('userData', JSON.stringify(user))
            router.push('/dashboard')
            setTimeout(() => {
              location.reload() // Reload the page after a brief delay
            }, 650)
          } else {
            Vue.swal({
              title: 'Hata',
              text: data.msg,
              icon: 'warning',
              background: '#FF4C51',
              confirmButtonColor: '#000',
            })
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
    const dialogClose = () => {
      loading.value = false
      e6.value = 1
      checkbox1.value = false
      checkbox2.value = false
      checkbox3.value = false
      dialogForm.value = false
    }

    onMounted(() => {
      lang.value = localStorage.getItem('lang')
      console.log('x = ' + localStorage.getItem('lang'))
    })

    return {
      handleFormSubmit,
      isPasswordVisible,
      username,
      isDark,
      password,
      lang,
      loading,
      errorMessages,
      dialogForm,
      perfectScrollbarOptions,
      e6,
      checkbox1,
      checkbox2,
      checkbox3,
      handleContractsSubmit,
      dialogClose,
      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiDraw,
        mdiEyeOffOutline,
        mdiCheckBold,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
  data: () => {
    return {
      languages: Object.values(i18n.messages),
    }
  },
  methods: {
    changeLang: lang => {
      localStorage.setItem('lang', lang)
      location.reload()
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
.overflow-contracts {
  overflow-y: scroll;
}
.ps-user-notifications {
  max-height: 300px;
  .ps__rail-y {
    display: block !important;
    opacity: 1 !important;
    .ps__thumb-y {
      opacity: 1 !important;
      background-color: #312d4b !important;
      width: 9px !important;
      height: 60px !important;
    }
  }
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
